// let apiUrl = (window.location.hostname === "localhost") ? "127.0.0.1" : window.location.hostname
// let protocol = (window.location.hostname === "localhost") ? "http://" : window.location.protocol+"//"
// let port = (window.location.hostname === "localhost") ? "80" : window.location.port

let apiUrl = (window.location.hostname === "localhost") ? "20.0.0.110" : window.location.hostname
let protocol = (window.location.hostname === "localhost") ? "http://" : window.location.protocol+"//"
let port = (window.location.hostname === "localhost") ? "8082" : window.location.port

export const config = {
    apiUrl,
    protocol,
    port
};