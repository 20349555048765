import React, { Component } from "react";
import { userService } from '../../services/services';

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            submitted: false,
            wrongCredentials: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { username, password } = this.state;
        if(username && password){
            let res = await userService.login(username,password)
            if(res && res.data && res.data.auth){
                // this.props.history.push('/dashboard')
                window.location.href = res.data.url
            }else{
                this.setState({ wrongCredentials: true });
                setTimeout(()=>{
                    this.setState({ wrongCredentials: false });
                },2000)
            }
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { username, password, submitted, wrongCredentials } = this.state;
        return (
            <div className="container-fluid h-100">
                <div className="row h-100">
                    <div className="col-md-5">
                        <div className="d-flex align-items-end login-left-side ml-4 pl-4 h-50">
                            <div className="mb-4">
                                <div className="logo_login"></div>
                                <h1>WELCOME</h1>
                                <h2>to your dashboard</h2>
                            </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-center">
                            {/* <img className="mb-4" src={require("./../../assets/logo.png")}></img> */}
                        </div>
                    </div>
                    <div className="col-md-7 login-background">
                        <div className="login-background-black position-absolute h-100 w-100"></div>
                        <div className="login-background-border position-absolute"></div>
                        <div className="login-right-side h-100 d-flex align-items-center justify-content-right mr-4 pr-4">
                            <div className="row w-100 text-right"> 
                                <div className="col-md-12">
                                    <h2 className="mt-4">Log in</h2>
                                    <h6 className="mb-5">To your account</h6>
                                </div>
                                <div className="col-md-6"></div>
                                <form className="col-md-6" name="form" onSubmit={this.handleSubmit}>
                                    <div className={'mb-4 form-group' + (submitted && !username ? ' has-error' : '')}>
                                        <input placeholder="Username" type="text" className="form-control" autocomplete="off" name="username" value={username} onChange={this.handleChange} />
                                        {submitted && !username &&
                                            <div className="help-block">Username is required</div>
                                        }
                                    </div>
                                    <div className={'mb-5 form-group' + (submitted && !password ? ' has-error' : '')}>
                                        <input placeholder="Password" type="password" className="form-control" autocomplete="off" name="password" value={password} onChange={this.handleChange} />
                                        {submitted && !password &&
                                            <div className="help-block">Password is required</div>
                                        }
                                    </div>
                                    <div className="pt-5 form-group">
                                        <button className="btn w-100" name="send">Login</button>
                                        {loggingIn}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div name="response" className={'login-alert w-25 ' + (wrongCredentials ? '' : 'd-none')}>
                    Access not allowed
                </div>
            </div>
        );
    }
}

