import { config } from '../config/config';
import axios from 'axios';

function login(username, password) {
    return axios({
        method: 'post', 
        url: `${config.protocol}${config.apiUrl}:${config.port}/api/authenticate`,
        data: {
            username:username, password:password
        }
    }).then(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(response.user));
            let data;
            if (!response.ok) {
                if (response.status === 401 || response.status === 404) {
                    // auto logout if 401 response returned from api
                    logout();
                    data = { "statusText":response.statusText, "status":response.status};
                }else{
                    data = response
                }
            }else{
                data = response
            }
            return data;
        }).catch((err)=>{
            return {"error" : err};
        });
}


function logout() {
    return axios({
        method: 'post', 
        url: `${config.protocol}${config.apiUrl}:${config.port}/api/logout`,
        data: {

        }
    }).then(response => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('user', JSON.stringify(response.user));
        let data;
        if (!response.ok) {
            if (response.status === 401 || response.status === 404) {
                data = { "statusText":response.statusText, "status":response.status};
            }else{
                data = response
            }
        }else{
            data = response
        }
        return data;
    }).catch((err)=>{
        return {"error" : err};
    });
}

export const userService = {
    login,
    logout
};