import React from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import LoginPage from './components/login/login'
import { HashRouter, Switch, Route } from "react-router-dom"

const App = () => (
  <HashRouter> {/* envolvemos nuestra aplicación en el Router  */}
    <Switch> {/* también la envolvemos en el componente Switch */}
      <Route path="/" component={LoginPage} exact /> {/* y creamos nuestras rutas */}
    </Switch>
  </HashRouter>
)

export default App;
